import React from 'react';
import { Card } from 'react-bootstrap';

const Blog = ({ blog }) => {
    const { title, slug, featuredImage } = blog;

    return (
        <Card className="text-center card-borderless">
            <Card.Link href={`${slug}`}>
                <Card.Img className="img-fluid" variant="top" src={featuredImage.fluid.srcWebp} alt={featuredImage.title}/>
                <Card.Body className="justify-content-center">
                    <Card.Title className="h6 p-0 m-0">{title}</Card.Title>
                </Card.Body>
            </Card.Link>
        </Card>
    )
}

export default Blog;
