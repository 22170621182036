import React from 'react';
import { Card } from 'react-bootstrap';

const Recipe = ({ recipe }) => {
    const { title, slug, featuredImage } = recipe;
    return (
        <Card className="text-center card-borderless">
            <Card.Link href={`/recipes/${slug}`} className="a-home" >
                {featuredImage &&
                <Card.Img className="card-img-recipes" variant="top" src={featuredImage.fluid.srcWebp} alt={featuredImage.title}/>}
                <Card.Body className="justify-content-center">
                    <Card.Title className="h6 p-0 m-0">{title}</Card.Title>
                </Card.Body>
            </Card.Link>
        </Card>
    )
}

export default Recipe;
