import React from 'react'
import { graphql } from 'gatsby';
import PageWrapper from '../components/Layout/PageWrapper';
import Recipe from '../components/Recipes/Recipe';
import Weekmenu from '../components/Weekmenu/Weekmenu';
import Blog from '../components/Blog/Blog';
import Title from '../components/Title';
import { Button, Col, Container, Row } from 'react-bootstrap';
import SEO from "../components/seo";
import StyledHero from "../components/StyledHero";

const Tags = ({ pageContext, data }) => {
    const { tag } = pageContext
    const { taggedPosts } = data;
    const { totalCount } = data.taggedPosts;
    const heroImage = data.tagBcg.childImageSharp.fluid;

    return (
        <PageWrapper>
            <SEO title="Tags" />
            <StyledHero img={heroImage} />
            <Container fluid>
                <Title title={tag} subtitle={`tags (${totalCount})`} />
                <Container fluid className="py-3">
                    <Row className="d-flex justify-content-center">
                        {taggedPosts.edges.map(({ node }) => {
                            switch (node.template) {
                                case "recipe-post":
                                    return (
                                        <Col xs={12} sm={6} md={4} lg={3} className="pb-4" key={node.id}>
                                            <Recipe recipe={node} />
                                        </Col>
                                    )
                                case "blog-post":
                                    return (
                                        <Col xs={12} sm={6} md={4} lg={3} className="pb-4" key={node.id}>
                                            <Blog blog={node} />
                                        </Col>
                                    )
                                case "weekmenu-post":
                                    return (
                                        <Col xs={12} sm={6} md={4} lg={3} className="pb-4" key={node.id}>
                                            <Weekmenu weekmenu={node} />
                                        </Col>
                                    )
                                default:
                                    break;
                            }
                        })
                        }
                    </Row>
                </Container>
                <Container className="pb-5 d-flex justify-content-center">
                    <Button className="a-home btn-blue" onClick={() => window.history.back()}>Terug naar vorige pagina</Button>
                </Container>
            </Container>
        </PageWrapper>
    )
};

export default Tags;

export const pageQuery = graphql`
query($tag: String) {
    tagBcg: file(relativePath: {eq: "tagsBcg.jpg"}) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 4160) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    taggedPosts: allContentfulRecipe(limit: 20000, sort: {fields: date, order: DESC}, filter: {tags: {in: [$tag]}}) {
        totalCount
        edges {
            node {
                id
                slug
                title
                template
                tags
                featuredImage {
                    fluid(quality: 100, maxHeight: 750) {
                        srcWebp
                    }
                    title
                }
            }
        }
    }
}
`