import React from 'react';
import { Card } from 'react-bootstrap';

const Weekmenu = ({ weekmenu }) => {
    const { slug, featuredImage } = weekmenu;

    return (
        <Card className="text-center">
            <Card.Link href={`${slug}`}>
                <Card.Img className="img-fluid" variant="top" src={featuredImage.fluid.srcWebp} alt={featuredImage.title}/>
            </Card.Link>
        </Card>
    )
}

export default Weekmenu;
